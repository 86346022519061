.footer
  background url('/static/images/footer-backround.jpg') center no-repeat
  background-size cover
  
  padding 25px 0
  
  
.footer-list
  reset-list()
  display flex
  
  &__item
    //width 50%
    width 0
    color #fff
    position relative
    
    &:first-child
      width 100%
    
    a
      width 40px
      height 40px
      
      position absolute
      right 0
      top 0
      bottom 0
      margin auto
    
.footer
  &__title
    display block
    font-family $alt-font-family
    font-size 28px
  
  &__copyright
    font-size 18px
    font-weight 700
    font-family $alt-font-family
    
  &__description
    display block
    