$sprite = json("sprite.json", { hash: true })

for $name, $data in $sprite
  .icon-{$name}
    display inline-block
    vertical-align middle
    background-image url('../images/sprite.png')
    background-repeat no-repeat
    add-property("background-position", $data.offset_x $data.offset_y)
    add-property("width", $data.width)
    add-property("height", $data.height)