.header
  background #fff
  position relative
  z-index 5
  
  box-shadow 0 0px 20px 0px rgba(204, 204, 204, 0.6)
  
  &__container
    display flex
    justify-content space-between
    
    overflow inherit

$viewportMenu = 920px

.header
  &-logo
    display flex
    align-items center
    
    position relative
    z-index 2
        
    &__image
      display block
      height 34px
      
      @media (max-width 1080px)
        height 28px
      
      @media (max-width $viewportMenu)
        height 24px
     
  
  &-menu
    reset-list()
    display flex
    
    @media (max-width $viewportMenu)
      position absolute
      right 0
      top 46px
      padding-top 8px
      background #000
      
      display block
      transition transform 0.2s ease
      
      transform translate3d(0, -100%, 0)
      
      &.is-open
        transform translate3d(0, 0, 0)
        
    
    &__item
      margin 0 8px
      position relative
      
      @media (min-width 921px)
        &:nth-of-type(2)
          display none
      
      @media (max-width $viewportMenu)
        margin 0
        background #000
        
        &:nth-of-type(2)
          margin-bottom 12px
          
        &:nth-of-type(3)
          border-top 1px solid #fff
          
          .header-menu__link
            display none
      
      &:hover
        .header-submenu
          transform translate3d(0,100%,0)
          
          @media (max-width $viewportMenu)
            transform translate3d(0,0,0)
      
    &__link
      font-family $alt-font-family
      
      text-decoration none
      font-weight 600
      font-size 15px
      line-height 80px
      padding 0 12px
      letter-spacing 0.03em

      display inline-block
      
      background #fff
      position relative
      z-index 2
      
      span
        position relative
        z-index 2
        
        &:before
          content ""
          background transparent
          
          absolute 10px -6px -2px
          z-index -1
          
          transition background 0.15s ease
          
      &:hover
        
        span
          &:before
            background $color-main
            
      @media (max-width $viewportMenu)
        line-height 32px
        padding 0 36px
        background #000
        color #fff
        
        width 100%
        box-sizing border-box
    
        span
          &:before
            display none
            
        &:hover
          color #ffe500
            
            
  &-submenu
    position absolute
    background #000
    color #fff
    list-style none
        
    right 0
    bottom 0
    
    padding 24px 32px
    box-sizing border-box
    
    width 100%
    font-size 14px
    
    transition transform 0.2s ease
    
    @media (max-width $viewportMenu)
      padding 14px 32px
      position relative
    
    &__item
      line-height 32px
      
    &__link
      display inline-block
      
      color #fff
      font-size 16px
      text-decoration none
      
      transition transform 0.2s ease
      transform translate3d(0,0,0)
            
      &:hover
        color $color-main
        
        transform translate3d(8px,0,0)
        
      i
        margin-right 6px
       
.header-mobile-menu
  display none
  align-items center
  
  margin 15px 0
  
  @media (max-width $viewportMenu)
    display flex
    
  &:before
    content ""
    width 250px
    
    position absolute
    top 0
    bottom 0
    right 0
    
    background #fff
        
.menu-burger
  width 24px
  cursor pointer
  padding 7px 0
  position relative
  
  div
    background #000
    height 2px
    position relative
    
    transition background 0.15s ease
    
    &:before
    &:after
      content ""
      position absolute
      
      width 100%
      height 100%
      
      top 0
      left 0
      
      background #000
      
      transition transform 0.2s ease
      
    &:before
      transform translate3d(0,-6px,0) 
    
    &:after
      transform translate3d(0,6px,0)
      
      
  &.is-open
    div
      background #fff
      
      &:before
        transform translate3d(0,0,0) rotate(45deg)
        
      &:after
        transform translate3d(0,0,0) rotate(-45deg)
      