html
body
  size 100%
  
body
  &.is-hide
    overflow hidden
    
  &.is-error
    display flex
    flex-direction column
    
    .site-error
      flex auto
      
      display flex
      flex-direction column
      justify-content center
      align-items center
      
      h1
        margin 0 0 12px
        
      .btn
        display inline-block
        margin-top 18px
 
$viewportMenu = 920px
.container
  width 100%
  max-width 1328px
  margin 0 auto
  padding 0 24px
  
  box-sizing border-box
  overflow hidden
  
  @media (max-width $viewportMenu)
    padding 0 24px
    
  @media (max-width 768px)
    padding 0 16px
    
  @media (max-width 460px)
    padding 0 12px

.header
.page
.footer
  width 100%
  

.tns-ovh .tns-inner
  margin 0 !important

