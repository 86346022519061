html
  font-family $base-font-family
  font-size $base-font-size
  font-weight $base-font-weight
  line-height $base-line-hight
  font-smoothing()

body
  background-color $color-base-bg
  color $color-base
  
  // display flex
//   
//   &.is-middle
//     flex-wrap wrap
//     align-content space-between
//       
//   &:not(.is-middle)
//     flex-direction column
//     
//     .page
//       flex 1 0 auto
      

a
  color $color-base
  transition-property color, background-color
  transition-duration 150ms
  &:focus
    outline none

::selection
  background $color-main
  color #000
  text-shadow none
