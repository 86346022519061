if $google-fonts is defined
  @import $google-fonts;

@font-face
  font-family 'icomoon'
  src  url('../fonts/icomoon.eot?h6vols');
  src  url('../fonts/icomoon.eot?h6vols#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?h6vols') format('truetype'),
    url('../fonts/icomoon.woff?h6vols') format('woff'),
    url('../fonts/icomoon.svg?h6vols#icomoon') format('svg')
  font-weight normal
  font-style normal


[class^="icon-"], [class*=" icon-"] 
  font-family 'icomoon' !important
  speak none
  font-style normal
  font-weight normal
  font-variant normal
  text-transform none
  line-height 1

  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale

.icon-airline_seat_individual_suite:before 
  content "\e632"

.icon-assignment_ind:before
  content "\e85e";

.icon-directions_car:before
  content "\e531"

.icon-local_airport:before
  content "\e53d"

.icon-local_mall:before
  content "\e54c"

.icon-texture:before
  content "\e421"

.icon-keyboard_arrow_right:before
  content "\e901"

.icon-keyboard_arrow_left:before
  content "\e902"

.icon-location:before 
  content "\e900"

