img
  max-width 100%
  
  
.page
  &-header
    position relative
    
    &__bg
      absolute 0
      
      background url('https://picsum.photos/1920/820') no-repeat center
      background-size cover
      
    &__wrapper
      position relative
      display table
      size 100% 560px
      
    &__inner
      display table-cell
      vertical-align bottom
      
      color #fff
      
    &__content
      position relative
      padding 0 0 48px
      
      &:before
        content ""
        absolute -32px 0 0 0
        
        background linear-gradient(to top, rgba(0,0,0,0.7), transparent)
    
    
.page-header
  &__container
    position relative
  
  &__title
    font-size 80px
    margin 0 0 8px 0
    

.breadcrumbs
  reset-list()
  display flex
  flex-wrap wrap
  
  &__item
    position relative
    padding-right 20px
    
    &--location
      padding-right 16px
    
    &:last-child
      padding-right 0
      
    
  
  &__link
    font-size 26px
    text-decoration none
    
    &:hover
      color $color-main
    
    
  .icon-location
    font-size 28px
    color $color-main
    
    position relative
    top 6px
    
  &__arrow
    font-size 32px
    line-height 39px

    
.breadcrumbs
  &__link
    color #fff
    
    
.page-menu
  reset-list()
  display flex
  justify-content center
  
  &__item
       
    &:hover
      & ^[0]__link
        background rgba(255, 255, 255, 0.62)
        
    &.is-active
      & ^[0]__link
        background rgba(255, 255, 255, 1)
    
  &__link
    display inline-block
    text-decoration none
    padding 12px 16px
    
    transition background 0.2s ease
    
.page
  &-topline
    &__content
      background $color-main
      box-shadow 0 0 15px 0 rgba(0,0,0,0.2)
      padding 4px 0
      
      &.is-fixed
        position fixed
        
        left 0
        right 0
        top 0
        
        z-index 999
        
    &__backlink
      text-decoration none
      line-height 2.2
      display inline-block
      font-weight 600
            
      i
        position relative
        top 2px
        
      @media (max-width 380px)
        i
          color #fff
          font-size 24px
          top 4px
          
          &:before
            color #fff
            position relative
            z-index 2
            
          &:after
            content ""
            position absolute
            width 100%
            background #000
            height 100%
            
            top 0
            left 0
    
        span
          display none
        
        
    &__share
      float right
      display flex
      align-items center
      height 35px
      
      &-text
        font-size 12px
        text-transform uppercase
        font-weight 600
        margin-right 12px
        
        @media (max-width 460px)
          display none
  
  
.topline-location
  position absolute
  
  display flex
  line-height 48px
  
  width 210px
  
  &__icon
    font-size 25px
    line-height inherit

    margin-right 12px
    
  &__text
    font-size 18px
    
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
  
  
.information
  position relative
  
.page-content
  margin 32px 0 48px
  
  &__cols
    display flex
    flex-direction row
    
    @media (max-width 768px)
      flex-direction column
    
    &-item
      &--text
        max-width 680px
        padding-right 120px
        
        @media (max-width $viewportMenu)
          padding-right 45px
          
        @media (max-width 768px)
          padding-right 0
        
      &--map
        flex auto
      
  
  &__category
    color #6d6d6d
    display block
    margin-bottom 8px
    
  &__intro
    font-size 20px
    
    p
      margin 0
     
  &__location
    line-height 24px
    margin-bottom 16px
    
    padding-left 32px
    position relative
    
    &-icon
      vertical-align middle
      font-size 24px
      margin-right 6px
      
      position absolute
      left 0
      
    a
    span
      
      transition text-shadow 0.2s ease
      
    a:hover
      text-shadow 0px 0px 4px #ffe500
      text-decoration none
      
  &__map
    width 100%
    height 90%
    
    min-height 420px
    max-height 600px
    min-width 320px
    
    box-shadow 0 0 24px -12px rgba(0, 0, 0, 0.2)
    
    @media (max-width 460px)
      min-width auto
      min-height auto
      height 340px
    
  
  &__full
    display none
    margin-top 32px
    
    ul
      padding-left 24px
    
    &.is-view
      display block
      
  &__open-full
    display table
    border-top 1px solid #ccc

    margin 50px auto 0
    padding 16px 10px 0
  
    font-size 18px
    font-weight 700
    letter-spacing 0.1em
    
    text-shadow 3px 3px 2px #ffe500
    color #252525
    
    text-decoration none
    text-transform uppercase
  
  
  
.photos
  &__inner
    position relative
    
    .slider-arrows
      &__item
        margin-top -14px
        
        &--left
          left 12px
        &--right
          right 12px
          
  &__source
    margin auto
    font-size 14px
    color #999
    margin-top 6px
    
    display none
    
    &.is-view
      display table
      
      
.image-source
  background #eaeaea
  
  margin 16px auto 0
  padding 16px 24px
  
  display none
  
  &.is-view
    display table

  &__item
    display inline-block
    margin 4px 6px
    padding 2px 4px
    
    
.slider-rsize
  
  &__image
    height 460px
    
    @media (max-width 960px)
      height 380px
      
    @media (max-width 660px)
      height 320px
      
    @media (max-width 460px)
      height 240px


    
  
.cities__content
  position relative
  
  .tns-nav
    display none
  
    
.slider-4v
  reset-list()
  display flex
  
  &__slide
    &-inner
      display flex
      margin -12px
    
  &__item
    padding 12px
    
    box-sizing border-box
    width 25%
    height 460px
   
   
.cities
  margin 80px 0 
  
    
.city-card
  display table
  size 100%
  
  border-radius 4px
  overflow hidden
  text-decoration none
  
  background-position center
  background-repeat no-repeat
  background-size cover
  
  &__inner
    display table-cell
    vertical-align bottom
    text-align center
    
  &__content
    position relative
    padding 0 0 40px 0
    
    
    &:before
      content ""
      absolute -20px 0 0 0
      background linear-gradient(to top, #000, transparent)
  
  &__number
    position relative
    size 40px
    text-align center
    line-height 40px
    
    margin auto
    
    font-size 16px
    font-weight 700
    
    &:before
      content ""
      absolute 0
      
      background #fff
      border-radius 6px
      transform rotate(45deg)
      
    span
      position relative
      
  &__title
    position relative
    color #fff
    
    font-size 24px
    margin-top 18px
    
.slider-arrows
  &__item
    border-radius 50%
    background #fff
    display inline-block

    size 40px
    
    position absolute
    top 50%
    margin-top -20px
    
    cursor pointer
    text-align center
    box-shadow 0 0 5px 0 rgba(0, 0, 0, 0.3)
    
    outline none
    
    &[aria-disabled=true]
      opacity 0.5
      cursor inherit
    
    i
      font-size 25px
      line-height 40px
      color #777
      position relative
      
    &--left
      left -18px
      i
        left -1px
        
    &--right
      right -18px
      i
        right -1px

  
.services
  background $color-main
  
  &__container
    max-width 1020px
    margin auto
    padding 40px 0
    
    @media (max-width $viewportMenu)
      padding 25px 0
          
    @media (max-width 540px)
      padding 18px 0
 
.services-list
  reset-list() 
  
  display flex
  flex-wrap wrap
  
  margin -16px
  
  @media (max-width $viewportMenu)
    margin -12px
  
  @media (max-width 540px)
    margin -8px
  
  
.service-item
  width 33.33333%
  padding 16px
  
  box-sizing border-box
  
  @media (max-width $viewportMenu)
    width 50%
    padding 12px
  
  @media (max-width 540px)
    width 100%
    padding 8px
    
  &__inner
    size 100% 90px
    border-radius 15px
    
    overflow hidden
    background #fff
    text-decoration none
  
    position relative
    box-sizing border-box
    display table
    vertical-align middle
    box-shadow 0 0 10px -6px rgba(0,0,0,0.6)
    
    transition transform 0.3s ease, box-shadow 0.3s ease
    
    &:hover
      box-shadow 0px 4px 15px -3px rgba(0,0,0,0.6)
      transform translate3d(0,-6px,0)
      
    @media (max-width 468px)
      height 80px
      
    @media (max-width 540px)
      height 60px
  
  &__icon
    display table-cell
    width 70px
    
    background #000
    
    vertical-align middle
    text-align center
    
    i
      font-size 26px
      vertical-align middle
      color #fff
    
  &__content
    display table-cell
    vertical-align middle
    padding 0 20px
    
    @media (max-width 468px)
      padding 0 16px
    
  &__title
    display block
    font-weight 700
    
  &__price
    color #2b2b2b
    font-size 14px
    
    display inline-block
    line-height 1.2
    margin-top 4px
    
    @media (max-width 540px)
      display none
  
    
.sights
  position relative
  
  padding 60px 0
  
  background-color #f6f6f6
  
  @media (max-width $viewportMenu)
    padding 45px 0
    
  @media (max-width 480px)
    padding 36px 0
  
    
  &:before
    background linear-gradient(to top,#f6f6f6,#fff)
    top -40px
    
  &:after
    background linear-gradient(to bottom,#f6f6f6,#fff)
    bottom -40px
    
  &__next-btn
    position relative
    text-decoration none
    background #020202
    padding 12px 60px
    border-radius 4px
    color #fff
    font-size 18px
    font-weight 600
    display table
    margin auto
    margin-top 40px
    text-transform uppercase
    margin-bottom 2px
    
    &:hover
      background #3e3e3e
    
    &:active
      top 2px
      
  
    
.list
  reset-list()
  display flex
  flex-wrap wrap
  
  margin -16px 0
  
  &__item
    box-sizing border-box
    
    width 100%
    padding 16px 0
    
  @media (min-width 460px) and (max-width 759px)
    display table
    margin -16px auto
    max-width 420px
      
    
  @media (min-width 760px)
    margin -20px -16px
    
    &__item
      width 50%
      padding 20px 16px
      
  @media (min-width 920px)
    margin -25px
    
    &__item
      width 50%
      padding 25px
      
   
  @media (min-width 960px)
    margin -20px -16px
    
    &__item
      width 33.33333%
      padding 20px 16px
      
  @media (min-width 1260px)
    margin -24px
    
    &__item
      width 33.33333%
      padding 24px
    
.list-card
  position relative
  height 100%
  
  border-radius 4px
  overflow hidden
  
  transition transform 0.2s ease, box-shadow 0.2s ease

  &:hover
    transform translate3d(0,-4px,0)
    box-shadow 0 4px 15px -2px rgba(0,0,0,0.2)
    
  &__info
    display block
    background #fff
    height 100%
    
    text-decoration none
    
    
  &__image
    display block
    width 100%
    
  &__content
    padding 20px
    
  &__title
    display block
    font-family $alt-font-family
    
    font-size 18px
    font-weight 700
    
  &__intro
    margin 12px 0
    
  &__category
    display inline-block
    
    font-size 14px
    font-style italic
    
    color #828282
    margin-bottom 2px
    
    
  &__city
    position absolute
    background #ffe500
    text-decoration none
    width 100%
    padding 0 20px
    line-height 48px
    box-sizing border-box
    bottom 0
    
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
    
    &:hover
      span
        text-decoration underline
    
    .icon-location
      position relative
      top 3px
      
      font-size 20px
      margin-right 6px
      
  &__count-places
    position relative
    display inline-block
    
    span
      position relative
    
    &:before
      content ""
      position absolute
      left -5px
      right -5px
      bottom 8px
      top 10px
        
      background-color $color-main
      
      
      
.sight-card
  &--location
    padding-bottom 48px
    box-sizing border-box
    
    
.routes-preview
  &__item
    clearfix()
    position relative
    
    &:first-child
      .routes-preview__image
        &:nth-of-type(-n+2) 
          &:before
            display none
    
  &__image
    float left
    width 33.33333%
    padding-top 20%
    background-position center
    background-repeat no-repeat
    background-size cover
    
    position relative
    
    &--big
      width 66.66666%
      padding-top 40%
      
    &:before
      content ""
      position absolute
      left 0
      right 0
      top 0
      
      height 1px
      background-color $color-main
     
    &:nth-of-type(n+2) 
      &:after
        content ""
        position absolute
        left 0
        top 0
        bottom 0
        
        width 1px
        background-color $color-main
  
      
.post-list
  reset-list()
  max-width 580px
  margin  auto
  
  &__item
    display flex
    
    margin-bottom 40px
    padding-bottom 40px
    
    border-bottom 1px solid #f2f2f2
    
  &__image
    height 150px
    
  &__content
    padding 0 24px 0
    
  &__title
    font-size 20px
    font-weight 700
    
  &__intro
    margin 6px 0 0
      
      
$viewportMenu = 920px  
.section-title
  font-size 36px
  line-height 46px
  font-weight 400
  
  border-left 4px solid #000
  padding-left 32px
  
  max-width 560px
  margin 0 0 56px
  
  @media (max-width $viewportMenu)
    font-size 28px
    line-height 38px
    margin-bottom 45px
    
  @media (max-width 440px)
    font-size 24px
    line-height 28px
    padding-left 14px
    margin-bottom 36px
    
  @media (max-width 380px)
    font-size 22px
    line-height 26px
    padding-left 10px
  
.place-title
  margin 40px 0 20px
  
  
.bg-gray
  position relative
  padding 60px 0
  
  background-color #f6f6f6
  
  &:before
  &:after
    content ""
    position absolute
    left 0
    right 0
    height 80px
    
    
  &:before
    background linear-gradient(to top,#f6f6f6,#fff)
    top -40px
    
  &:after
    background linear-gradient(to bottom,#f6f6f6,#fff)
    bottom -40px
  
.sezon
  padding 40px 0
  
  
.month-list
  display flex
  flex-wrap wrap
  border 4px dashed $color-main
  
  &__item
    width 25%
    padding 24px
    box-sizing border-box
    border 1px solid #f2f2f2
    border-width 1px 0 0 1px
  
.popular-icons
  size 28px 24px
  position relative
  margin auto
  
  background-image url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAwCAYAAAAhOEsMAAAAcElEQVR4AWLwySwEtFsHGACFQBCGAZg77AEGuv/x3mB5DFZSJLv8gC+ypKUa3g81oZhFCQ0NK0qogRpehn3QMB0pztwRhqlQQz9AndujBrY/LxR+6PeqY+KEjurY8EmoJpDiBS9Aw4ZQ9DK89wdo+AFtKfA41Y59oQAAAABJRU5ErkJggg==')
  background-size contain
  
  &__item
    position absolute
    top 0
    bottom 0
    left 0
    
    background-image url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAwCAYAAAAhOEsMAAAAjElEQVR4AWLwySwEtFsHGADDMBSGj5DT5Dw9Qs+Y27xbbEFHPFrZbNQEP1Q/KpvqowruDVtr4nUPo+5JBpp3UMb7GCmjkH4CxQOjsSYMGasHQroeDk12JOnPkVnjI5qHSRaPfKHOQ5kEr0doK0BZhLgBsS0saEkM/gFkpAuk1753r46CBTFJ//cGKHgC8hXtYFzGMywAAAAASUVORK5CYII=')
    background-size contain
    
    
.month-item
  &__title
    font-weight 700
        
  &__info
    display flex
    margin-top 16px
  
  &__el
    width 50%
    text-align center
  
.s-table
  &__tr
    width 100%
    display flex
    
  &__td
    width 50%
    display flex
    text-align center
    
    &:first-child
      text-align left
    
    &-2
      width 50%
      
    &--bold
      font-weight 700
      
      

      

  
