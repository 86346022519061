@keyframes spin
  100%
    transform rotate(360deg)

.svg-spinner-icon
  position relative
  display block
  animation spin 1s steps(12)infinite

.icon-spinner
  position relative
  display inline-block
  vertical-align middle
  overflow hidden
